<template>
  <div class="font-picker">
    <!-- font -->
    <div class="frmBlock__dropdownField">
      <div class="DAV-dropdown">
        <button
          class="DAV-dropdown__button"
          :class="{
            'DAV-dropdown__disabled': $route.path === '/option'
          }"
          @click.stop="changeVisible"
        >
          <span>{{ selectValue }}</span>
        </button>
        <ul
          class="DAV-dropdown__list"
          v-show="isVisible"
          v-click-outside:toggleLayer
        >
          <li v-for="(font, index) in fontList" :key="index">
            <button
              @click="
                selectValue = font.typeface.name;
                value.code = font.name;
                updateOption('code', value.code);
                isVisible = false;
              "
              class="font-select"
            >
              <div class="font-name">
                <div>{{ font.typeface.name }}</div>
                <div class="line"></div>
                <div class="no">
                  {{
                    font.name === "no1"
                      ? "대표서체"
                      : font.name === "no2"
                      ? "보조서체1"
                      : "보조서체2"
                  }}
                </div>
              </div>
              <img class="font-example" :src="font.typeface.previewImage.url" />
              <div v-if="font.name === value.code" class="check-circle"></div>
              <div v-else class="check-circle-no"></div>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- size -->
    <div class="frmBlock__layout frmBlock__layout--number">
      <span class="frmBlock__unit">px</span>
      <input
        type="number"
        class="frmBlock__textField"
        min="10"
        max="999"
        @keyup="keyupSize($event.target.value)"
        @blur="blurSize"
        v-model="value.size"
        inputmode="numeric"
        pattern="[0-9]*"
      />
      <div
        class="frmBlock__counter"
        :class="{
          'frmBlock__counter--option': $route.path === '/option'
        }"
      >
        <button
          type="button"
          class="frmBlock__counter--up"
          @click="clickSize('plus')"
        >
          <span>증가</span>
        </button>
        <button
          type="button"
          class="frmBlock__counter--down"
          @click="clickSize('minus')"
        >
          <span>감소</span>
        </button>
      </div>
    </div>
    <!-- bold -->
    <div
      class="font-style"
      :class="{'font-style-on': value.bold}"
      @click="
        value.bold = !value.bold;
        updateOption('bold', value.bold);
      "
    >
      <img v-if="value.bold" src="@/assets/images/admin/icon_b.svg" />
      <img v-else src="@/assets/images/admin/icon_b_no.svg" />
    </div>
    <!-- italic -->
    <div
      class="font-style"
      :class="{'font-style-on': value.italic}"
      @click="
        value.italic = !value.italic;
        updateOption('italic', value.italic);
      "
    >
      <img v-if="value.italic" src="@/assets/images/admin/icon_i.svg" />
      <img v-else src="@/assets/images/admin/icon_i_no.svg" />
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");

export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      selectValue: null,
      isVisible: false,
      fontList: [],
      value: {
        code: null,
        size: null,
        bold: null,
        italic: null
      }
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? this.option.componentOption.properties
        : this.option.value;
    },
    ...ModuleEditor.mapGetters(["getUserFontUpdateData"]),
    ...ModuleTheme.mapGetters(["getThemeTypeFacesUpdateData"])
  },
  watch: {
    getUserFontUpdateData() {
      this.userFontList();
    },
    getThemeTypeFacesUpdateData() {
      this.partnerThemeFontList();
    }
  },
  mounted() {
    // this.value = this.option.value;
    this.extractValue();
    if (this.$route.path === "/option") {
      this.selectValue = "대표서체(Default)";
    } else {
      this.$route.query.mode === "partner"
        ? this.partnerThemeFontList()
        : this.userFontList();
    }
  },
  methods: {
    // 파트너 테마 폰트 목록
    partnerThemeFontList() {
      let payload = {};
      payload["id"] = this.$route.params.themeId;

      this.actThemeTypeFaces(payload).then(response => {
        this.fontList = response.data;
        const filter = this.fontList.filter(v => v.name === this.value.code);
        if (filter.length) {
          this.selectValue = filter[0].typeface.name;
        } else {
          this.selectValue = this.fontList[0].typeface.name;
          this.value.code = this.fontList[0].name;
          this.updateOption("code", this.value.code);
        }
      });
    },
    // 회원 폰트 목록
    userFontList() {
      let payload = {
        user_theme_id: this.$route.params.themeId
      };
      this.actUserFontList(payload).then(response => {
        this.fontList = response.data;
        const filter = this.fontList.filter(v => v.name === this.value.code);
        if (filter.length) {
          this.selectValue = filter[0].typeface.name;
        } else {
          this.selectValue = this.fontList[0].typeface.name;
          this.value.code = this.fontList[0].name;
          this.updateOption("code", this.value.code);
        }
      });
    },
    // 레이어 Toggle
    toggleLayer(value) {
      this.isVisible = !value ? value : !this.isVisible;
    },
    // 프로퍼티 업데이트 (파트너)
    updateProperty(code, size, bold, italic) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: code.id,
            key: code.key,
            initialValue: code.value
          },
          {
            id: size.id,
            key: size.key,
            initialValue: size.value
          },
          {
            id: bold.id,
            key: bold.key,
            initialValue: bold.value
          },
          {
            id: italic.id,
            key: italic.key,
            initialValue: italic.value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption(type, value) {
      this.$emit("updateOption", this.option.componentOption.key, type, value);

      if (this.$route.path === "/option") {
        let mProperties = this.option.modified.properties;
        let obj = {};

        mProperties.forEach(item => {
          obj[item.key] = {
            id: item.id,
            key: item.key,
            value: type === item.key ? value : item.initialValue
          };
        });

        this.updateProperty(obj.code, obj.size, obj.bold, obj.italic);
      }
    },
    // 폰트 사이즈 입력 제한
    keyupSize(v) {
      if (Number(v) && v > 9 && v < 1000) {
        this.updateOption("size", this.value.size);
      }
    },
    // 폰트 사이즈 입력 제한
    blurSize() {
      if (this.value.size < 10 || !Number(this.value.size)) {
        this.value.size = 10;
        this.updateOption("size", this.value.size);
      }
      if (this.value.size > 999) {
        this.value.size = 999;
        this.updateOption("size", this.value.size);
      }
    },
    // 폰트 사이즈 버튼 제한
    clickSize(type) {
      if (
        (type === "plus" && Number(this.value.size) === 999) ||
        (type === "minus" && Number(this.value.size) === 10)
      ) {
        return;
      }

      if (type === "plus") {
        this.value.size = Number(this.value.size) + 1;
      } else {
        this.value.size = Number(this.value.size) - 1;
      }
      this.updateOption("size", this.value.size);
    },
    //값 추출
    extractValue() {
      let isOption = this.$route.path === "/option" ? true : false;
      if (!isOption) {
        this.value = this.option.value;
      } else {
        let obj = {};

        this.initialValue.forEach(item => {
          // obj[item.key] = {
          //   id: item.id,
          //   key: item.key,
          //   value: item.initialValue
          // };
          if (item.key === "bold" || item.key === "italic") {
            this.value[item.key] = Number(item.initialValue) ?? 0;
            obj[item.key] = {
              id: item.id,
              key: item.key,
              value: Number(item.initialValue) ?? 0
            };
          } else if (item.key === "size") {
            this.value[item.key] = item.initialValue ?? "14";
            obj[item.key] = {
              id: item.id,
              key: item.key,
              value: item.initialValue ?? "14"
            };
          } else {
            this.value[item.key] = item.initialValue;
            obj[item.key] = {
              id: item.id,
              key: item.key,
              value: item.initialValue ?? "no1"
            };
          }
        });
        this.updateProperty(obj.code, obj.size, obj.bold, obj.italic);
      }
    },
    changeVisible() {
      if (this.$route.path === "/option") return false;
      this.isVisible = true;
    },
    ...ModuleTheme.mapActions(["actThemeTypeFaces"]),
    ...ModuleEditor.mapActions(["actUserFontList"])
  }
};
</script>

<style lang="scss" scoped>
.font-picker {
  // font select
  .frmBlock__dropdownField {
    margin-bottom: 8px;
  }

  .DAV-dropdown__list {
    padding: 8px;
    max-height: none;
  }

  .font-select {
    background-image: none;
    height: auto;
    position: relative;
    padding: 8px;
    border-radius: 8px;

    &:hover {
      background: #f6f6f6;
    }

    .font-name {
      font-size: 12px;
      color: #606060;
      margin-bottom: 3px;
      display: flex;

      .line {
        background: #dcdcdc;
        width: 1px;
        height: 12px;
        margin: 3px 7px;
      }

      .no {
        color: #8d8d8d;
      }
    }

    .font-example {
      width: 100%;
    }

    .check-circle {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      box-sizing: border-box;
      background-image: url($path + "ico_check.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 26px;
      position: absolute;
      top: 30px;
      right: 16px;
    }

    .check-circle-no {
      border: 1px solid #d8dae5;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      box-sizing: border-box;
      position: absolute;
      top: 30px;
      right: 16px;
    }
  }

  // bold, italic
  .font-style {
    border: 1px solid #d8dae5;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 54px;
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    margin-left: 8px;
  }

  .font-style-on {
    border: 1px solid #8d8d8d;
  }

  // size
  .frmBlock__layout {
    &--dropdown {
      display: inline-block;
      width: 128px;

      .frmBlock__dropdownField {
        width: 100%;
      }
    }
    &--number {
      display: inline-block;
      width: 120px;
      margin-top: 0px;
      vertical-align: top;

      .frmBlock__counter {
        position: absolute;
        top: 4px;
        left: 8px;
        width: 24px;
      }
      .frmBlock__counter button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 20px;
      }
      .frmBlock__counter--up {
        border-radius: 4px 4px 0px 0px;
      }
      .frmBlock__counter--down {
        border-radius: 0px 0px 4px 4px;
      }
      .frmBlock__counter--up:after,
      .frmBlock__counter--down:after {
        color: #797979;
        transform: scale(0.5, 0.4);
      }
      .frmBlock__unit {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 34px;
        color: #8d8d8d;
        font-size: 14px;
      }
      .frmBlock__textField[type="number"]:enabled:focus
        + .frmBlock__counter
        button,
      .frmBlock__textField[type="number"]:enabled:hover
        + .frmBlock__counter
        button {
        background-color: #d2f8ec;
        background-repeat: no-repeat;
        background-size: 8px auto;
      }
      .frmBlock__textField[type="number"]:enabled:focus
        + .frmBlock__counter
        button:after,
      .frmBlock__textField[type="number"]:enabled:hover
        + .frmBlock__counter
        button:after {
        color: #54c7a2;
      }
      .frmBlock__textField[type="number"]:disabled
        + .frmBlock__counter
        button:after,
      .frmBlock__textField[type="number"]:disabled
        + .frmBlock__counter
        button:after {
        color: #dcdcdc;
      }
      .frmBlock__counter--up:enabled:hover:after,
      .frmBlock__counter--down:enabled:hover:after {
        color: #309574 !important;
      }
      .frmBlock__counter span {
        text-indent: -9999px;
      }
      .frmBlock__counter--up:after {
        content: "\25B2";
        margin-top: 3px;
      }
      .frmBlock__counter--down:after {
        content: "\25BC";
        margin-top: -3px;
      }
      /* 반응형 - mobile */
      @media screen and (max-width: 1023px) {
        .frmBlock__counter {
          display: none;
          &--option {
            display: block;
          }
        }
      }
    }
  }
}
</style>
